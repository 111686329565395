import React from "react";
import { SignUpContainer } from './SignUpContainer';

export function LandingPageNoFooterMain() {
    return (
        <>
            <main className='no-footer-main-container scrollable'>
                <div className='no-footer-main-item'>
                    <h1 className='heading'>Welcome to</h1>
                    <h1 className='heading'>Beheer</h1>
                    <h1 className='desktop-heading'>Welcome to <span className='color-red'>Beheer</span></h1>
                </div>

                <div className='no-footer-main-item padding-top-50 landing-page-no-footer-main-item'>
                    <SignUpContainer />
                    <p className='copyright-statement'><b>&copy;</b> Beheer 2025</p>
                </div>
            </main>
        </>
    )
}